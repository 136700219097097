@use 'styles/master';

.page {
   position: relative;

   display: flex;
   align-items: center;
   justify-content: center;

   padding: 20px 70px 60px;
   max-width: 1200px;
   min-height: 100vh;
}

.header {
   position: fixed;
   top: 10px;
   left: 10px;
   z-index: 1;
}

.settings, .home {
   --icon-background: transparent;
   --icon-background-hover: transparent;
   --button-background: transparent;
   font-size: 30px;
   position: absolute;
}

.settings {
   i { transition: transform .2s }
   &:hover i { transform: rotate(180deg) }
}

@media (max-width: 820px) {
   .page { padding: 20px 20px 60px }
}

@media (max-width: 500px) {
   .page { padding: 20px 15px 60px }
}
