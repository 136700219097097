@use 'styles/master';

.scroll-x {
   overflow-x: auto;
   overflow-y: hidden;
}

.scroll-y {
   overflow-x: hidden;
   overflow-y: auto;
}

.scroll-xy {
   overflow-x: auto;
   overflow-y: auto;
}

.scroll-x, .scroll-y, .scroll-xy {
   &::-webkit-scrollbar-button:vertical:start:decrement,
   &::-webkit-scrollbar-button:vertical:end:increment {
      display: none;
      visibility: hidden;
      height: 0;
      background-color: transparent;
   }

   &::-webkit-scrollbar,
   &::-webkit-scrollbar-track {
      width: 3px;
      height: 3px;
      background-color: transparent;
   }

   &::-webkit-scrollbar-thumb {
      width: 3px;
      height: 3px;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, .5);
   }
}

.scroll-hidden {
   &::-webkit-scrollbar,
   &::-webkit-scrollbar-track,
   &::-webkit-scrollbar-thumb {
      display: none;
      visibility: hidden;
      width: 0;
      height: 0;
   }

   &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: transparent;
   }
}

.block {
   padding: 10px 15px;
   border-radius: 12px;
   background-color: #{master.$block-light};
   box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.2);
}

.focus-light { --color: #{master.$text-light}; }
.focus-dark { --color: #{master.$text-dark}; }

.focus-light,
.focus-dark {
   border-radius: 6px;
   transition: box-shadow .2s;

   &:focus-visible {
      position: relative;
      z-index: 1;
      box-shadow: 0px 0px 0px 2px var(--color);
   }
}

.m0auto {
   margin: 0 auto;
}

.empty {
   text-align: center;
   color: #{master.$secondary-hard};
}
