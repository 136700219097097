@use 'styles/master';

.primary,
.info,
.success,
.warning,
.error {
   --icon-color: #{master.$text-light};
   --text-color: #{master.$text-light};
   --button-background: #{master.$block-light};
}

.primary-inner,
.info-inner,
.success-inner,
.warning-inner,
.error-inner {
   --alert-background: #{master.$block-light};
   --icon-color: #{master.$text-light};
   --text-color: #{master.$text-dark};
}

.primary {
   --alert-background: #{master.$primary};
   --icon-background: #{master.$primary-hard};
   --button-color: #{master.$primary};
   --button-background-hover: #{master.$primary-medium};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$primary}, 0px 0px 0px 4px #{master.$block-light};
}

.info {
   --alert-background: #{master.$info};
   --icon-background: #{master.$info-hard};
   --button-color: #{master.$info};
   --button-background-hover: #{master.$info-medium};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$info}, 0px 0px 0px 4px #{master.$block-light};
}

.success {
   --alert-background: #{master.$success};
   --icon-background: #{master.$success-hard};
   --button-color: #{master.$success};
   --button-background-hover: #{master.$success-medium};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$success}, 0px 0px 0px 4px #{master.$block-light};
}

.warning {
   --alert-background: #{master.$warning};
   --icon-background: #{master.$warning-hard};
   --button-color: #{master.$warning-hard};
   --button-background-hover: #{master.$warning-medium};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$warning}, 0px 0px 0px 4px #{master.$block-light};
}

.error {
   --alert-background: #{master.$error};
   --icon-background: #{master.$error-hard};
   --button-color: #{master.$error};
   --button-background-hover: #{master.$error-medium};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$error}, 0px 0px 0px 4px #{master.$block-light};
}

.primary-inner {
   --icon-background: #{master.$primary};
   --button-color: #{master.$primary};
   --button-background: #{master.$primary-medium};
   --button-background-hover: #{master.$primary-darker};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$block-light}, 0px 0px 0px 4px #{master.$primary};
}

.info-inner {
   --icon-background: #{master.$info};
   --button-color: #{master.$info};
   --button-background: #{master.$info-medium};
   --button-background-hover: #{master.$info-darker};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$block-light}, 0px 0px 0px 4px #{master.$info};
}

.success-inner {
   --icon-background: #{master.$success};
   --button-color: #{master.$success};
   --button-background: #{master.$success-medium};
   --button-background-hover: #{master.$success-darker};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$block-light}, 0px 0px 0px 4px #{master.$success};
}

.warning-inner {
   --icon-background: #{master.$warning};
   --button-color: #{master.$warning-hard};
   --button-background: #{master.$warning-medium};
   --button-background-hover: #{master.$warning-darker};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$block-light}, 0px 0px 0px 4px #{master.$warning};
}

.error-inner {
   --icon-background: #{master.$error};
   --button-color: #{master.$error};
   --button-background: #{master.$error-medium};
   --button-background-hover: #{master.$error-darker};
   --button-box-shadow-focus: 0px 0px 0px 2px #{master.$block-light}, 0px 0px 0px 4px #{master.$error};
}

.alert {
   position: relative;

   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 12px;

   padding: 10px 15px;

   border-radius: 6px;
   background-color: var(--alert-background);
   box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.2);

   &.padding { padding-right: 30px }
}

.container {
   display: flex;
   align-items: center;
   gap: 12px;
}

.icon {
   display: flex;
   align-items: center;
   justify-content: center;

   min-width: 40px;
   max-width: 40px;
   height: 40px;

   font-size: 24px;
   color: var(--icon-color);

   border-radius: 6px;
   background-color: var(--icon-background);
}

.text {
   font-weight: 500;
   color: var(--text-color);
}

.close {
   position: absolute;
   top: 5px;
   right: 5px;

   font-size: 10px;
   --icon-color: #{master.$secondary-darker};
   --icon-background: #{master.$block-light};
   --icon-color-hover: #{master.$text-dark};
   --icon-background-hover: #{master.$block-light};
}

.button {
   --color-hover: var(--button-color);
   --background-hover: var(--button-background-hover);

   padding: 0.8em 1.2em;
   color: var(--button-color);
   background-color: var(--button-background);

   &:focus-visible {
      box-shadow: var(--button-box-shadow-focus);
   }
}

@media (max-width: 650px) {
   .alert {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
   }

   .button { width: 100% }
}
