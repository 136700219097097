@keyframes rotate {
   0%   { transform: rotate(0deg)   }
   100% { transform: rotate(360deg) }
}

@keyframes stretch {
   0%   { height: 15px }
   50%  { height: 60px }
   100% { height: 15px }
}

@keyframes pulse {
   0%   { opacity: 0 }
   100% { opacity: 1 }
}

@mixin createToTop ($percents) {
   @keyframes to-top {
      0%   { transform: translateY(#{$percents}); opacity: 0 }
      100% { transform: translateY(0);            opacity: 1 }
   }
}
