@use 'styles/master';

html {
   width: 100vw;
   overflow-x: hidden;
}

body {
   background-color: #{master.$background};
}

#root {
   position: relative;
   z-index: 1;
   font-size: 14px;
   font-weight: 400;
   font-family: 'Inter', sans-serif;
   line-height: 1.5;
   color: #{master.$text-dark};
}
