.banner {
   position: fixed;
   left: 50%;
   bottom: 10px;
   z-index: 100;
   transform: translateX(-50%);

   width: 900px;
   max-width: 90%;
}
