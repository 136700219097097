@use 'styles/master';
@use 'styles/animations';

.container {
   display: flex;
   justify-content: center;
   align-items: center;

   width: 100%;
   height: 100%;
}

.loader {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 60px;

   span {
      display: block;

      margin: 0 7px;
      width: 15px;
      height: 15px;

      border-radius: 10px;
      background-color: #{master.$primary};
      animation: stretch 1.2s infinite;

      &:nth-child(1) { animation-delay: .15s }
      &:nth-child(2) { animation-delay: .30s }
      &:nth-child(3) { animation-delay: .45s }
      &:nth-child(4) { animation-delay: .60s }
      &:nth-child(5) { animation-delay: .75s }
   }
}
