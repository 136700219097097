.fade {
   &-enter {
      opacity: 0;
   }

   &-enter-active {
      opacity: 1;
      transition: opacity .4s;
   }

   &-exit {
      opacity: 1;
   }

   &-exit-active {
      opacity: 0;
      transition: opacity .4s;
   }
}

.fade-top {
   &-enter {
      opacity: 0;
      transform: translateY(-50px);
   }

   &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: transform .4s, opacity .4s;
   }

   &-exit {
      opacity: 1;
      transform: translateY(0);
   }

   &-exit-active {
      opacity: 0;
      transform: translateY(-50px);
      transition: transform .4s, opacity .4s;
   }
}

.fade-left {
   &-enter {
      opacity: 0;
      transform: translateX(-50px);
   }

   &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: transform .4s, opacity .4s;
   }

   &-exit {
      opacity: 1;
      transform: translateX(0);
   }

   &-exit-active {
      opacity: 0;
      transform: translateX(-50px);
      transition: transform .4s, opacity .4s;
   }
}

.fade-bottom {
   &-enter {
      opacity: 0;
      transform: translateY(50px);
   }

   &-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: transform .4s, opacity .4s;
   }

   &-exit {
      opacity: 1;
      transform: translateY(0);
   }

   &-exit-active {
      opacity: 0;
      transform: translateY(50px);
      transition: transform .4s, opacity .4s;
   }
}

.fade-right {
   &-enter {
      opacity: 0;
      transform: translateX(50px);
   }

   &-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: transform .4s, opacity .4s;
   }

   &-exit {
      opacity: 1;
      transform: translateX(0);
   }

   &-exit-active {
      opacity: 0;
      transform: translateX(50px);
      transition: transform .4s, opacity .4s;
   }
}
