@use 'styles/master';

:root {
   --toastify-color-light: #{master.$block-light};
   --toastify-color-info: #{master.$info};
   --toastify-color-success: #{master.$success};
   --toastify-color-error: #{master.$error};
   --toastify-color-warning: #{master.$warning};

   --toastify-z-index: 999;
   --toastify-toast-width: 320px;
   --toastify-toast-min-height: 64px;
   --toastify-toast-max-height: 800px;

   --toastify-font-family: 'Inter', sans-serif;
   --toastify-text-color-light: #{master.$text-dark};
}
