.background {
   position: fixed;
   top: 0;
   left: 0;

   min-width: 100vw;
   max-width: 100vw;
   min-height: 100vh;
   max-height: 100vh;

   pointer-events: none;
   z-index: -1;
}
