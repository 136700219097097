.konami {
   position: fixed;
   top: 65px;
   right: -12px;
   z-index: 10;

   display: block;
   width: 100px;
   height: auto;

   background-color: transparent;

   img {
      transform: rotate(285deg);
   }
}
